<template>
  <div>
    <div class="form-group row">
      <label class="col-md-auto control-label">Total </label>
      <div class="col-md-auto">
        {{ this.formatNumber(totalRows) }} 
      </div>
    </div>
    <b-table
      sticky-header="500px"
      :items="items"  
      :fields="fields"
      show-empty
      :busy="isLoading"
      responsive="sm"
      hover
      sortByFormatted="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :tbody-tr-class="rowClass"
    >
      <template #cell(No)="rows">
        {{ (currentPage - 1) * perPage + (rows.index + 1) }}
      </template>
       <template #cell(Period)="rows">
        <div style="width:10em">
          {{ formatDate(rows.item.disbursement_date) }}
        </div>
      </template>
      <template #cell(base_price)="rows">
        {{ formatPrice(rows.item.final_price,"IDR") }}
      </template>
      <template #cell(qty)="rows">
        {{ rows.item.quantity }}
      </template>
      <template #cell(revenue)="rows">
        <div v-b-tooltip.hover.right="rows.item.revenue_name">
          {{ formatPrice(rows.item.disbursement,"IDR") }}
        </div>
      </template>
      <template #cell(client)="rows">
        {{ formatPlatform(rows.item.client_id) }}
      </template>
      <template #cell(platform)="rows">
        <platform :platform="rows.item.platform_id"></platform>
      </template>
      <template #cell(transaction_code)="row">
       <router-link :to="{ 
          path: `/subledger/detail/${row.item.transaction_code}`}" 
          class="link">
          {{ row.item.transaction_code }}
        </router-link>
     </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <b-spinner
            class="align-middle"
            variant="dark"
            type="grow"
            label="Loading .."
          >
          </b-spinner>
          Loading ..
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import platform from "../platform/platform.vue";
import moment from "moment";
  export default {
    name: 'SubledgerTable',
    comments: {
      platform
    },
    props: {
      items:{
        type: Array,
        default: () => []
      },
      isGroup: {
        type: Boolean,
        default: true
      },
      isLoading: {
        type: Boolean,
        default: true
      },
      totalRows: {
        type: Number,
        default: 0
      },
      accountID: {
        type: String,
        default: "C-002829808"
      },
      inventLocationId:{
        type: String,
        default: "10198"
      },
      accountType: {
        type: String,
        default: "Revenue"
      },
      perPage: {
        type: Number,
        default: 100
      
      },
      currentPage: {
        type: Number,
        default: 1
      }
    },
    data () {
      return {
        sortBy: 'Period',
        sortDesc: true,
        fields: [
          { key:'No', label: 'No'},
          {
            key: 'Period',
            label: 'Period',
          },
          {
            key :'base_price',
            label: 'Base Price',
          },
          {
            key :'qty',
            label: 'Qty',
            sortable: true,
          },
          {
            key: 'revenue',
            label: 'Revenue',
            thClass: 'text-right px-5',
            tdClass: 'text-right px-5',
            sortable: true
          },
          {
            key: "vendor_name",
            label: "Vendor",
            sortDirection: "asc"
          },
          {
            key: "offer_type",
            label: "Report Type",
            tdClass: 'text-capitalize',
            sortDirection: "asc"
          },
          { key:'client',label:'Platform' },
          { key:'transaction_code', label: "Transaction Code"},
          {
            key: "description",
            label: "Description",
          }
        ]
      }
    },
    methods: {
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      formatDate(date) {
        return moment(date).format("DD MMMM YYYY");
      },
      formatPlatform(clientID) {
        switch (clientID) {
          case "1":
            return `Ebooks`;
          case "2":
            return `Ebooks`;
          case "67":
            return "E-perpus";
          case "82":
            return "Gramedia";
          case "7":
            return "Ebooks";
          default:
            return "Other";
        }
      },
      formatNumber(value) {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      rowClass(item) {
        if (!item || !item?.disbursement) return;
        if (item.disbursement < 0) return 'table-danger';
      }
    },
    
  }
</script>

<style lang="css" scoped>
@import '../../assets/css/table.css';

.table-fn {
  font-family:"Avenir Next"
}
</style>