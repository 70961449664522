<template>
  <div class="text-center">
    <span v-if="platform === 1">
      <i class="fa fa-apple" aria-hidden="true"></i>
    </span>
    <span v-if="platform === 2">
      <i class="fa fa-android" aria-hidden="true"></i>
    </span>
    <span v-if="platform === 3">
      <i class="fa fa-windows" aria-hidden="true"></i>
    </span>
    <span v-if="platform === 4">
      <i class="fa fa-firefox" aria-hidden="true"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "platform",
  props: {
    platform: {
      type: Number,
      required: true
    }
  }
};
</script>
