var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rows books"
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.offer_type == 'single' || _vm.offer_type == 'subscription' ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.aggregate, function (data, no) {
    return _c('div', {
      key: no,
      staticClass: "col-md-4"
    }, [_c('b-card', {
      key: no,
      staticClass: "shadow-lg",
      attrs: {
        "no-body": true
      }
    }, [_c('b-card-body', {
      staticClass: "p-0 clearfix"
    }, [_c('i', {
      staticClass: "fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
    }), _vm.aggregate ? _c('div', {
      staticClass: "h5 text-uppercase mb-0 pt-3"
    }, [data.client_id == 1 ? _c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v(" Ebooks ")]) : _vm._e(), data.client_id == 82 ? _c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v(" Gramedia.com ")]) : _vm._e(), data.client_id == 67 ? _c('label', {
      attrs: {
        "for": ""
      }
    }, [_vm._v(" E-perpus ")]) : _vm._e()]) : _c('div', {
      staticClass: "h6 text-info mb-0 pt-3"
    }, [_c('b-spinner', {
      attrs: {
        "type": "grow",
        "label": "Loading..."
      }
    })], 1), _vm.aggregate ? _c('div', {
      staticClass: "text-muted text-uppercase font-md"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(data.amount, "IDR")) + " / "), _c('span', {
      staticClass: "text-muted text-sm-center"
    }, [_vm._v("(" + _vm._s(data.total) + ")")])]) : _c('div', {
      staticClass: "h6 text-info mb-0 pt-3"
    }, [_c('b-spinner', {
      attrs: {
        "type": "grow",
        "label": "Loading..."
      }
    })], 1)])], 1)], 1);
  }), 0) : _c('div', [_c('div', {
    staticClass: "col-md-4"
  }, [_c('b-skeleton-wrapper', {
    attrs: {
      "loading": _vm.isLoading
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function () {
        return [_c('b-card', [_c('b-skeleton', {
          attrs: {
            "width": "85%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "55%"
          }
        }), _c('b-skeleton', {
          attrs: {
            "width": "70%"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_vm._v("Revenue")]), _c('div', {
    staticClass: "col-auto"
  }, [_c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(_vm.amount, "IDR")) + " ")])])])]), _vm.offer_type == 'buffet' ? _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary",
      "disabled": _vm.isDownloadingBuffet
    },
    on: {
      "click": _vm.onDownloadBuffet
    }
  }, [_vm.isDownloadingBuffet ? _c('b-spinner', {
    staticClass: "align-middle",
    attrs: {
      "small": ""
    }
  }) : _c('em', {
    staticClass: "fa fa-download"
  })], 1)], 1) : _vm._e()], 1), _vm.offer_type == 'buffet' ? _c('TableDetail', {
    attrs: {
      "items": _vm.items,
      "totalRows": parseInt(_vm.totalRows),
      "isLoading": _vm.isLoading,
      "perPage": _vm.perPage,
      "currentPage": _vm.currentPage
    }
  }) : _vm._e(), _vm.offer_type == 'single' || _vm.offer_type == 'subscription' || _vm.offer_type == 'buffet bundling' || _vm.offer_type == 'bundle' ? _c('TableSingle', {
    attrs: {
      "items": _vm.items,
      "totalRows": parseInt(_vm.totalRows),
      "isLoading": _vm.isLoading,
      "perPage": _vm.perPage,
      "currentPage": _vm.currentPage
    }
  }) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-start mt-2 pt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1), _vm.offer_type == 'buffet' ? _c('div', {
    staticClass: "col-md-12 mt-1"
  }, [_c('h3', [_vm._v(" 5 Popular Reading Premium")]), !_vm.isLoading ? _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.populars, function (popular) {
    return _c('div', {
      key: popular.id,
      staticClass: "col-md-2"
    }, [_c('div', {
      staticClass: "cards"
    }, [_c('img', {
      staticClass: "img-thumbnail cover",
      attrs: {
        "src": _vm.basePath + _vm.removeBucket(popular.thumb_image_normal),
        "alt": popular.item_name
      }
    }), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "card-text text-left ml-3"
    }, [_c('strong', [_vm._v(" Read by " + _vm._s(popular.people) + " User ")]), _c('br'), _c('small', [_vm._v(" " + _vm._s(_vm.SecondToHours(popular.duration)) + " ")])])])])]);
  }), 0) : _c('div', [_c('b-skeleton-img')], 1)]) : _vm._e(), _c('hr', {
    staticClass: "primary"
  }), _vm.offer_type == 'single' ? _c('div', {
    staticClass: "col-md-12 mt-1"
  }, [_c('h3', [_vm._v(" 5 Popular Single Sales")]), _c('div', {
    staticClass: "row"
  }, _vm._l(_vm.populars, function (popular) {
    return _c('div', {
      key: popular.id,
      staticClass: "col-md-2"
    }, [_c('div', {
      staticClass: "card border-0"
    }, [_c('img', {
      staticClass: "img-thumbnail cover",
      attrs: {
        "src": _vm.basePath + _vm.removeBucket(popular.thumb_image_normal),
        "alt": "Card image cap"
      }
    }), _c('div', {
      staticClass: "row"
    }, [_c('div', {
      staticClass: "card-text"
    }, [_vm._v(" Revenue "), _c('br'), _c('strong', [_vm._v(" " + _vm._s(_vm.formatPrice(popular.amount, "IDR")) + " ")]), _vm._v(" (terjual " + _vm._s(popular.qty) + ") ")])])])]);
  }), 0)]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }