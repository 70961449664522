var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_vm.platform === 1 ? _c('span', [_c('i', {
    staticClass: "fa fa-apple",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm.platform === 2 ? _c('span', [_c('i', {
    staticClass: "fa fa-android",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm.platform === 3 ? _c('span', [_c('i', {
    staticClass: "fa fa-windows",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm.platform === 4 ? _c('span', [_c('i', {
    staticClass: "fa fa-firefox",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }