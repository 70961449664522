<template>
  <div class="rows books">
    <div class="card mt-2">
      <div class="card-body">
        <div class="row" v-if="offer_type =='single' || offer_type =='subscription'">
          <div class="col-md-4" v-for="(data, no ) in aggregate" :key="no">
            <b-card :no-body="true" :key="no" class="shadow-lg">
              <b-card-body class="p-0 clearfix">
                <i
                  class="fa fa-line-chart bg-success p-4 font-2xl mr-3 float-left"
                ></i>
                <div v-if="aggregate" class="h5 text-uppercase mb-0 pt-3">
                  <label for="" v-if="data.client_id ==1">
                    Ebooks
                  </label>
                  <label for="" v-if="data.client_id ==82">
                    Gramedia.com
                  </label>
                  <label for="" v-if="data.client_id ==67">
                    E-perpus
                  </label>
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>

                <div v-if="aggregate" class="text-muted text-uppercase font-md">
                  {{ formatPrice(data.amount,"IDR")}} /
                  <span class="text-muted text-sm-center">({{data.total}})</span>
                </div>
                <div v-else class="h6 text-info mb-0 pt-3">
                  <b-spinner type="grow" label="Loading..."></b-spinner>
                </div>
              </b-card-body>
            </b-card>
          </div>
        </div>
        <div v-else>
          <div class="col-md-4">
            <b-skeleton-wrapper :loading="isLoading">
              <template #loading>
                <b-card>
                  <b-skeleton width="85%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                </b-card>
              </template>
            </b-skeleton-wrapper>
          </div>
        </div>
        <div class="col-md-12">
          <b-row>
            <b-col>
              <div class="form-group row">
                <div class="col-auto">Revenue</div>
                <div class="col-auto">
                  <strong> {{ formatPrice(amount,"IDR")}} </strong>
                </div>
              </div>
            </b-col>
            <b-col class="text-right" v-if="offer_type =='buffet'">
              <b-button size="sm" variant="primary" @click="onDownloadBuffet" :disabled="isDownloadingBuffet">
                <b-spinner small class="align-middle" v-if="isDownloadingBuffet"></b-spinner>
                <em class="fa fa-download" v-else></em>
              </b-button>
            </b-col>
          </b-row>
          <TableDetail v-if="offer_type =='buffet'"
            :items="items" 
            :totalRows="parseInt(totalRows)" 
            :isLoading="isLoading" 
            :perPage="perPage"
            :currentPage="currentPage"
            />
          <TableSingle 
            v-if="offer_type =='single' || offer_type =='subscription' || offer_type =='buffet bundling' || offer_type =='bundle'"
            :items="items" 
            :totalRows="parseInt(totalRows)" 
            :isLoading="isLoading" 
            :perPage="perPage"
            :currentPage="currentPage"
          />
           <b-row class="justify-content-start mt-2 pt-2">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
        <div class="col-md-12 mt-1" v-if="offer_type =='buffet'">
          <h3> 5 Popular Reading Premium</h3> 

          <div class="row" v-if="!isLoading">
            <div class="col-md-2" v-for="popular in populars" :key="popular.id">
              <div class="cards">
                <img 
                  class="img-thumbnail cover" 
                  :src="basePath + removeBucket(popular.thumb_image_normal)" 
                  :alt="popular.item_name">
                <div class="row">
                <div class="card-text text-left ml-3">
                  <strong> Read by {{ popular.people }} 
                    User
                  </strong>
                  <br>
                  <small>
                    {{ SecondToHours(popular.duration) }}
                  </small>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <b-skeleton-img></b-skeleton-img>
          </div>
        </div>
        <hr class="primary">
        <div class="col-md-12 mt-1" v-if="offer_type =='single'">
          <h3> 5 Popular Single Sales</h3> 

          <div class="row">
            <div class="col-md-2" v-for="popular in populars" :key="popular.id">
              <div class="card border-0">
                 <img 
                  class="img-thumbnail cover" 
                  :src="basePath + removeBucket(popular.thumb_image_normal)" 
                  alt="Card image cap">
                <div class="row">
                  <div class="card-text">
                    Revenue
                    <br>
                    <strong>  {{ formatPrice(popular.amount,"IDR")}}  </strong> (terjual {{ popular.qty }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState,mapGetters, mapActions} from 'vuex'
import TableDetail from "../../components/subledger/table.vue"
import TableSingle from "../../components/subledger/table-single.vue"
import moment from "moment";
  export default {
    name: 'SubledgerVendor',
    components: {
      TableDetail,
      TableSingle,
    },
    data () {
      return {
        Dates: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD"),
        },
        range: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().format("YYYY-MM-DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
        currentPage: 1,
        perPage: 100,
        basePath:'https://ebooks.gramedia.com/ebook-covers/',
        offer_type: '',
        isDownloadingBuffet: false,
      }
    },
    computed: {
      ...mapGetters('finance',['amount']),
      ...mapState({
        items: (state) => state.finance.item_wallet,
        isLoading: (state) => state.finance.isLoading,
        totalRows: (state) => state.finance.totalWallet,
        amount: (state) => state.finance.totalWalletAmount,
        populars: (state) => state.finance.populars,
        aggregate: (state) => state.finance.aggregates,
      })
    },
    watch: {
      currentPage: function() {
        this.actionSearch()
      }
    },
    mounted() {
      this.actionSearch()
      let offer_type =  this.$route.query.offer_type
      this.offer_type = offer_type.toLowerCase()
    },
    methods: {
      //
      ...mapActions('finance',['fetchReportOffers','sendReportDetailBuffetByPeriod','fetchReportDetailGroupByClient']),
      actionSearch(){
        let period = this.$route.params.id
        let payload = {
          period: moment(period).format("YYYY-MM-DD"),
          offer_type: this.$route.query.offer_type,
          limit: this.perPage,
          page : this.currentPage
        };
        this.fetchReportOffers(payload)
        if(payload.offer_type == 'single' || payload.offer_type == 'subscription'){
          this.fetchReportDetailGroupByClient(payload)
        }
        
      },
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      removeBucket(str) {
        console.log(`original ${str}`);
        if (str.substring(0, 6) == "images") {
          return str.substring(9);
        } else {
          return str;
        }
      },
      formatSeconToDays(duration){
        let days = Math.floor(duration / (24 * 60 * 60));
        let hours = Math.floor((duration - days * 24 * 60 * 60) / (60 * 60));
        let minutes = Math.floor((duration - days * 24 * 60 * 60 - hours * 60 * 60) / 60);
        let seconds = Math.floor(duration - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60);
        return `${days} days ${hours} hours ${minutes} minutes ${seconds} seconds`
      },
      SecondToHours(duration){
        let v = duration / 3600
        if (v > 1000 && v < 10000) {
          return parseFloat(v).toFixed(2) + "K hours"
        }
        return  parseFloat(v).toFixed(2) + " hours"
      },
      async onDownloadBuffet() {
        this.isDownloadingBuffet = true;
        const response = await this.sendReportDetailBuffetByPeriod({
          period: this.$route.params.id
        });

        if (response?.status == 200) {
          const emailAccepted = (response.data?.data?.accepted ?? []).join(', ');
          let message = 'Report successfully sent to this email ';
          message += `
          <br>
          <small>
            (${emailAccepted})
          </small>
          `;
          this.$swal('Success Downloaded', message, 'success')
          this.isDownloadingBuffet = false;
        } else {
          let message = `please contact your administrator with this code (${response?.status})`;
          this.$swal('Download Fail!!', message, 'error')
          this.isDownloadingBuffet = false;
        }
      }
    }
  }
</script>
<style scoped>
.books {
  font-family:"Avenir Next" ,"effra", Helvetica, Arial, sans-serif;
}
.cover {
  height: auto;
  width: 190px;
  padding:5px;
  margin: 5px 5px;
}
.center {
  width:90%;
  margin-left: auto;
  margin-right: auto;
}
.primary {
  border: 1px solid #0060af;
}
</style>