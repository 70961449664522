<template>
  <div>
    <div class="form-group row">
      <label class="col-md-auto control-label">Total </label>
      <div class="col-md-auto">
        {{ totalRows }} 
      </div>
    </div>
    <b-table
      sticky-header="500px"
      responsive="sm"
      :items="items"
      :fields="fields"
      show-empty
      :busy="isLoading"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
     >
      <template #cell(No)="rows">
        {{ (currentPage - 1) * perPage + (rows.index + 1) }}
      </template>
     <template #cell(Period)="row">
       {{ formatDate(row.item.disbursement_period) }}
     </template>
     <template #cell(disbursement)="row">
       {{ formatPrice(row.item.disbursement,"IDR") }}
     </template>
      <template #cell(transaction_code)="row">
       <router-link :to="{ 
          path: `/reading-buffets/${row.item.transaction_code}/vendor/${row.item.vendor_id}`}" 
          class="link">
          {{ row.item.transaction_code }}
        </router-link>
     </template>
     <template #cell(detail)="row">
        <router-link :to="{ 
          path: `/reading-buffets/${row.item.transaction_code}/vendor/${row.item.vendor_id}`}" 
          class="btn btn-sm btn-success">
          <i class="fa fa-clock-o"></i>
        </router-link>
     </template>
     <template v-slot:table-busy>
      <tr>
        <td colspan="9">
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Please Wait...</strong>
          </div>
        </td>
      </tr>
      </template>
   </b-table>
  </div>
</template>

<script>
import moment from "moment";
  export default {
    name: 'SubledgerTable',
    props: {
      items:{
        type: Array,
        default: () => []
      },
      isGroup: {
        type: Boolean,
        default: true
      },
      isLoading: {
        type: Boolean,
        default: false
      },
      totalRows: {
        type: Number,
        default: 0
      },
      accountID: {
        type: String,
        default: "C-002829808"
      },
      inventLocationId:{
        type: String,
        default: "10198"
      },
      accountType: {
        type: String,
        default: "Revenue"
      },
      perPage: {
        type: Number,
      },
      currentPage: {
        type: Number,
      },
       
    },
    data () {
      return {
        fields: [
          { key:'No', label: 'No'},
          {
            key: "vendor_name",
            label: "Vendor",
            sortable: true,
            sortDirection: "asc"
          },
          {
            key: "offer_type",
            label: "Report Type",
            tdClass: 'text-capitalize',
            sortDirection: "asc"
          },
          {
            key: "disbursement",
            label: "Amount",
            thClass: 'text-right px-5',
            tdClass: 'text-right px-5',
            sortable: true,
          },
          { key: "Period", label:'Period'},
          { key:'transaction_code', label:'Transaction Code'},
          { key:'description', label:'Descriptions'},
          {
            key: "detail",
            label:'Detail Duration',
            thClass: 'text-center',
            tdClass: 'text-center',
          },
        ],
        sortBy: 'disbursement',
        sortDesc: true,
      }
    },
    methods: {
      formatPrice(value, currency) {
        if (value) {
          var formatter = null;
          if (currency == "USD") {
            formatter = new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: currency,
            });
          } else {
            formatter = new Intl.NumberFormat("en-ID", {
              style: "currency",
              currency: currency,
            });
          }

          return formatter.format(value);
        }

        return "0";
      },
      formatDate(date) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    
  }
</script>

<style lang="css" scoped>
@import '../../assets/css/table.css';

.table-fn {
  font-family:"Avenir Next"
}

.sr-only {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    right: 0;
    color: red;
}
.sr-only:before {
    content: "V";
}
</style>