var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-md-auto control-label"
  }, [_vm._v("Total ")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_vm._v(" " + _vm._s(_vm.totalRows) + " ")])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "responsive": "sm",
      "items": _vm.items,
      "fields": _vm.fields,
      "show-empty": "",
      "busy": _vm.isLoading,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-icon-left": ""
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (rows) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (rows.index + 1)) + " ")];
      }
    }, {
      key: "cell(Period)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.disbursement_period)) + " ")];
      }
    }, {
      key: "cell(disbursement)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.disbursement, "IDR")) + " ")];
      }
    }, {
      key: "cell(transaction_code)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: ("/reading-buffets/" + (row.item.transaction_code) + "/vendor/" + (row.item.vendor_id))
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.transaction_code) + " ")])];
      }
    }, {
      key: "cell(detail)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "btn btn-sm btn-success",
          attrs: {
            "to": {
              path: ("/reading-buffets/" + (row.item.transaction_code) + "/vendor/" + (row.item.vendor_id))
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-clock-o"
        })])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('tr', [_c('td', {
          attrs: {
            "colspan": "9"
          }
        }, [_c('div', {
          staticClass: "text-center text-danger my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(" Please Wait...")])], 1)])])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }