var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-md-auto control-label"
  }, [_vm._v("Total ")]), _c('div', {
    staticClass: "col-md-auto"
  }, [_vm._v(" " + _vm._s(this.formatNumber(_vm.totalRows)) + " ")])]), _c('b-table', {
    attrs: {
      "sticky-header": "500px",
      "items": _vm.items,
      "fields": _vm.fields,
      "show-empty": "",
      "busy": _vm.isLoading,
      "responsive": "sm",
      "hover": "",
      "sortByFormatted": "true",
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "tbody-tr-class": _vm.rowClass
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (rows) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (rows.index + 1)) + " ")];
      }
    }, {
      key: "cell(Period)",
      fn: function (rows) {
        return [_c('div', {
          staticStyle: {
            "width": "10em"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(rows.item.disbursement_date)) + " ")])];
      }
    }, {
      key: "cell(base_price)",
      fn: function (rows) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(rows.item.final_price, "IDR")) + " ")];
      }
    }, {
      key: "cell(qty)",
      fn: function (rows) {
        return [_vm._v(" " + _vm._s(rows.item.quantity) + " ")];
      }
    }, {
      key: "cell(revenue)",
      fn: function (rows) {
        return [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: rows.item.revenue_name,
            expression: "rows.item.revenue_name",
            modifiers: {
              "hover": true,
              "right": true
            }
          }]
        }, [_vm._v(" " + _vm._s(_vm.formatPrice(rows.item.disbursement, "IDR")) + " ")])];
      }
    }, {
      key: "cell(client)",
      fn: function (rows) {
        return [_vm._v(" " + _vm._s(_vm.formatPlatform(rows.item.client_id)) + " ")];
      }
    }, {
      key: "cell(platform)",
      fn: function (rows) {
        return [_c('platform', {
          attrs: {
            "platform": rows.item.platform_id
          }
        })];
      }
    }, {
      key: "cell(transaction_code)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: ("/subledger/detail/" + (row.item.transaction_code))
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.transaction_code) + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }